import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "../styles/productDetail.css";
import Header from "../components/Header";
import axios from "axios";
import Footer from "../components/Footer";

const ProductDetail = () => {
  const { id } = useParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const [product, setProduct] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/products/${id}`)
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => {
        console.error("Error fetching product details:", error);
      });
  }, [id]);

  useEffect(() => {
    if (descriptionRef.current) {
      const descriptionElement = descriptionRef.current;
      const isTextoCorto =
        descriptionElement.scrollHeight > descriptionElement.clientHeight;
      setIsButtonVisible(isTextoCorto);
    }
  }, [product]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formatPrice = (price) => {
    return `$${new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)}`;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!product) {
    return (
      <>
        <Header />
        <p>Cargando...</p>
      </>
    );
  }

  return (
    <>
      <Header />

      <div className="product-detail-container">
        <div className="product-image">
          <img
            src={`/images/${product.image}`}
            alt={product.name}
            onClick={openModal}
          />
        </div>

        <div className="product-info">
          <h1>{product.name}</h1>
          <p className="price">{formatPrice(product.price)}</p>
          <h2>Descripción</h2>
          <p
            className={`description ${isExpanded ? "expanded" : "collapsed"}`}
            ref={descriptionRef}
          >
            {product.description}
          </p>

          {isButtonVisible && (
            <button className="toggle-button" onClick={toggleExpand}>
              {isExpanded ? "Ver menos" : "Ver más"}
            </button>
          )}
        </div>
      </div>

      <Footer />

      {isModalOpen && (
        <div className="modal-product-detail" onClick={closeModal}>
          <div className="modal-content-product-detail">
            <img src={`/images/${product.image}`} alt="Imagen grande" />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetail;
