import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Section from "../components/RandomProducts";
import Payments from "../components/Payments";
import Products from "../components/Products";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <Section />
        <Payments />
        <Products />
        <Footer />
      </main>
    </>
  );
};

export default Home;
