import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/section.css";
import axios from "axios";

const Section = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_API}/products/random`,
        );
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="section-container">
      <div className="text-container">
        <h2>Alondra</h2>
        <p>Bienvenidos a nuestro mundo aromático</p>
        <Link
          onClick={() => scrollToElement("seccion-productos")}
          className="btn-section"
        >
          Ver productos
        </Link>
      </div>

      <div className="image-container">
        {loading ? (
          <div className="spinner"></div>
        ) : (
          products.map((product, index) => (
            <Link key={index} to={`/product/${product.id}`}>
              <img src={`/images/${product.image}`} alt={product.name} />
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default Section;
