import "../styles/footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container" id="seccion-contacto">
      <div className="text-container">
        <h2> Alondra</h2>
        <p>
          Descubre la esencia de la naturaleza en cada uno de nuestros
          productos. Fragancias exclusivas y naturales que transforman tu
          espacio en un oasis de tranquilidad.
        </p>
      </div>

      <div className="redes-container">
        <Link to="https://www.facebook.com/Alondra.aromas" target="_blank">
          <i className="fa-brands fa-facebook-f"></i>
        </Link>
        <Link
          to="https://www.instagram.com/alondra_aromas?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          target="_blank"
        >
          <i className="fa-brands fa-instagram"></i>
        </Link>
        <Link to="https://wa.me/3329643254" target="_blank">
          <i className="fa-brands fa-whatsapp"></i>
        </Link>

        <div className="copy-container">
          <h4>©2024 Alondra - Todos los derechos reservados</h4>
        </div>
      </div>
    </div>
  );
};

export default Footer;
