import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/products.css";
import { Link } from "react-router-dom";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/products`)
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const formatPrice = (price) => {
    return `$${new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)}`;
  };

  return (
    <div className="products-container" id="seccion-productos">
      <h2>Productos</h2>

      <div className="products-grid">
        {loading ? (
          <div className="spinner"></div>
        ) : (
          products.map((product) => (
            <div key={product.id} className="product-card">
              <img src={`/images/${product.image}`} alt={product.name} />
              <div className="content-prod">
                <h3>{product.name}</h3>
                <p>{formatPrice(product.price)}</p>
                <Link to={`/product/${product.id}`} className="btn-ver-mas">
                  Ver más
                </Link>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Products;
